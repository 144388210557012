<template>
  <SpinnerComponent
    :isFull="true"
    :isActive="loader"
    :text="text"
  ></SpinnerComponent>

  <div class="column is-12 is-12-mobile fondo">
    <div
      v-if="products.length > 0"
      class="is-flex is-justify-content-center is-align-items-center"
      style="flex-direction: column; min-height: 100vh"
    >
      <h3
        class="text-red py-3 mb-3 column is-11-mobile is-9-tablet is-align-self-baseline"
      >
        <b>Ingresa</b> tus datos
      </h3>
      <div
        class="column is-8-desktop is-11-mobile is-8-tablet check-background mb-4"
        id="stepsCheck"
      >
        <!-- HEADER -->
        <div
          class="column is-12 is-12-table is-12-mobile is-flex p-2 check-header is-justify-content-center"
        >
          <div
            v-for="(row, index) in [1, 2, 3, 4, 5]"
            :key="index"
            class="column p-0 is-flex align-items-center is-justify-content-center"
          >
            <button
              :id="'btn-header-check-' + index"
              class="button is-medium column is-5 is-4-mobile p-0"
              @click="changeActive(index + 1)"
              :class="btnActive === index + 1 ? 'check-is-active' : 'color-red'"
            >
              <span
                class="icon is-medium is-size-5 is-size-5-mobile has-text-weight-bold"
              >
                {{ index + 1 }}
              </span>
            </button>
          </div>
        </div>

        <Products v-if="btnActive === 1" :products="products"></Products>
        <Info v-if="btnActive === 2"></Info>
        <Address v-if="btnActive === 3"></Address>
        <Pays v-if="btnActive === 4"></Pays>
        <RegisterPay v-if="btnActive === 5"></RegisterPay>

        <div class="column is-12 is-flex background-red footer-check">
          <div class="column is-6 p-0">
            <router-link
              to="/shopping-cart"
              class="button is-light has-text-white is-size-6-desktop is-size-6-mobile"
              >Volver al inicio</router-link
            >
          </div>
          <div class="column is-6 p-0">
            <button
              @click="changeActive(btnActive + 1)"
              class="button is-light has-text-white is-size-6-desktop is-size-6-mobile"
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="no-item">
      <h2 class="color-red has-text-weight-bold is-size-3-desktop">
        No hemos encontrado items en su carrito.
      </h2>
    </div>
  </div>
</template>

<script src="./check-out.ts" />
<style lang="scss" src="./check-out.scss" />
